import { FeatureGroup } from 'leaflet'

function random(seed) {
    const x = Math.sin(seed) * 10000
    return x - Math.floor(x)
}

function shuffleArray(array, seed) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(random(seed++) * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
    }
}

export const NonOverlappingFeatureGroup = FeatureGroup.extend({
    _distanceFactor: 1.5,
    onAdd(map) {
        this.eachNonOverlappingLayer(map.addLayer, map)
    },
    eachNonOverlappingLayer(method, context) {
        const seenLayers = []
        const entries = Object.values(this._layers)
        shuffleArray(entries, this._map.getZoom())
        for (const layer of entries) {
            const layerPoint = this._map.latLngToLayerPoint(layer._latlng)
            let doesNotOverlapp = true
            for (const otherLayer of seenLayers) {
                const otherPoint = this._map.latLngToLayerPoint(
                    otherLayer._latlng
                )
                const radius = Math.max(
                    layer.options.radius,
                    otherLayer.options.radius
                )
                const distance = layerPoint.distanceTo(otherPoint)
                if (distance < radius * this._distanceFactor) {
                    doesNotOverlapp = false
                    break
                }
            }
            if (doesNotOverlapp) {
                method.call(context, layer)
                seenLayers.push(layer)
            }
        }
        return this
    },
})
