<template>
    <LPolygon
        :lat-lngs="getLatLngsFromLocationGeofence(location.geofence)"
        :color="strokeColor"
        :fill-color="fillColor"
        :fill-opacity="fillOpacity"
        class="polygon"
        @click="locationClicked()"
    />
</template>

<script>
import { LPolygon } from 'vue2-leaflet'

import LocationsHelper from '@/mixins/LocationsHelper'

export default {
    name: 'LocationPolygon',
    components: {
        LPolygon,
    },
    mixins: [LocationsHelper],
    props: {
        location: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        filled: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        latLngs() {
            return this.getLatLngsFromLocationGeofence(this.location.geofence)
        },
        strokeColor() {
            return this.isSelected ? 'blue' : 'black'
        },
        fillColor() {
            return this.strokeColor
        },
        fillOpacity() {
            return this.filled ? undefined : 0
        },
    },
    methods: {
        locationClicked() {
            this.$emit('locationClicked', this.location)
        },
    },
}
</script>
