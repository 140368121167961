<template>
    <LFeatureGroup ref="createLocation" />
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { polygon } from 'leaflet'
import { LFeatureGroup } from 'vue2-leaflet'

import 'leaflet-editable'

import LocationsHelper from '../mixins/LocationsHelper'

export default {
    name: 'CreateLocationLayer',
    components: {
        LFeatureGroup,
    },
    mixins: [LocationsHelper],
    data() {
        return {
            polygon: null,
        }
    },
    computed: {
        ...mapState('createLocation', [
            'isResettingLocationPolygon',
            'locationPolygonInitial',
        ]),
    },
    watch: {
        isResettingLocationPolygon() {
            if (this.polygon && this.isResettingLocationPolygon) {
                this.removeCurrentPolygon()
                this.setLocationPolygon([])
                this.setResettingLocationPolygon(false)
                this.startNewPolygon()
            }
        },
        locationPolygonInitial(geofence) {
            if (geofence) {
                this.removeCurrentPolygon()

                this.polygon = polygon(
                    this.getLatLngsFromLocationGeofence(geofence)
                )
                this.polygon.addTo(this.$refs.createLocation.mapObject)
                this.polygon.enableEdit()
                this.setLocationPolygon(this.polygon.toGeoJSON())

                this.polygon.on('editable:editing', () => {
                    this.setLocationPolygon(this.polygon.toGeoJSON())
                })

                this.setLocationPolygonInitial(null)
            }
        },
    },
    mounted() {
        this.startNewPolygon()
    },
    beforeDestroy() {
        if (this.polygon) {
            this.removeCurrentPolygon()
            this.setLocationPolygon([])
        }
    },
    methods: {
        ...mapMutations('createLocation', [
            'setDrawingLocationPolygon',
            'setLocationPolygon',
            'setLocationPolygonInitial',
            'setResettingLocationPolygon',
        ]),
        startNewPolygon() {
            this.polygon = this.$refs.createLocation.parentContainer.mapObject.editTools.startPolygon()
            this.polygon.editor.enable()

            this.polygon.on('editable:editing', () => {
                this.setLocationPolygon(this.polygon.toGeoJSON())
            })

            this.polygon.on('editable:drawing:end', () => {
                this.setDrawingLocationPolygon(false)
            })

            this.setDrawingLocationPolygon(true)
        },
        removeCurrentPolygon() {
            if (this.polygon) {
                this.polygon.disableEdit()
                this.polygon.remove()
            }
        },
    },
}
</script>
