<template>
    <LFeatureGroup>
        <template v-for="(location, index) in locations">
            <LocationPolygon
                :key="`location-${index}`"
                :location="location"
                :is-selected="
                    activeLocationOnMap && activeLocationOnMap.id == location.id
                "
                :filled="!location.geojson_url"
                @locationClicked="locationClicked(location)"
            />

            <LocationGeoJson
                v-if="location.geojson_url"
                :key="`geojson-${index}`"
                :geojson-url="location.geojson_url"
            />
        </template>
    </LFeatureGroup>
</template>

<script>
import { mapState } from 'vuex'
import { LFeatureGroup } from 'vue2-leaflet'

import LocationsHelper from '@/mixins/LocationsHelper'
import LocationGeoJson from './LocationGeoJson'
import LocationPolygon from './LocationPolygon'

export default {
    components: {
        LFeatureGroup,
        LocationGeoJson,
        LocationPolygon,
    },
    mixins: [LocationsHelper],
    props: {
        locations: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapState('location', ['activeLocationOnMap']),
    },
    watch: {
        activeLocationOnMap(location) {
            if (location) {
                const bounds = this.getLatLngsFromLocationGeofence(
                    location.geofence
                )
                this.$emit('alignMap', bounds)
            }
        },
    },
    methods: {
        locationClicked(location) {
            this.$router.push({
                name: 'location',
                params: {
                    id: location.id,
                },
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "satelitemap": "Satellite map",
        "standardmap": "Standard map"
    },
    "de": {
        "satelitemap": "Satelliten Karte",
        "standardmap": "Standard Karte"
    },
    "fr": {
        "satelitemap": "Carte satellite",
        "standardmap": "Carte standard"
    },
    "it": {
        "satelitemap": "Carto Satelitte",
        "standardmap": "Carto Normalo"
    }
}
</i18n>
