<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="cls-1"
            d="M.75,17.189,11.47,6.47a.749.749,0,0,1,1.06,0L23.25,17.189"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.cls-1 {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
    fill-rule: evenodd;
}
</style>
