<template>
    <LCircleMarker
        v-if="assetHistoryPoint"
        :lat-lng="assetHistoryPoint"
        :radius="radius"
        :weight="4"
        :color="color"
        fill-color="white"
        :fill-opacity="0.75"
        :interactive="false"
    />
</template>

<script>
import { mapState } from 'vuex'
import { LCircleMarker } from 'vue2-leaflet'

export default {
    name: 'HistoryPointLayer',
    components: {
        LCircleMarker,
    },
    data() {
        return {
            color: process.env.VUE_APP_COLOR_ACCENT,
        }
    },
    computed: {
        ...mapState('map', ['showAccuracy']),
        ...mapState('tracker', ['assetHistoryPoint', 'trackers']),
        radius() {
            return this.showAccuracy
                ? this.trackers.find(item => item.id == this.$route.params.id)
                      ?.last_gps_measurement?.gps_accuracy || 14
                : 14
        },
    },
}
</script>
