<template>
    <LCircle v-if="radius" :lat-lng="latLng" :radius="radius" />
</template>

<script>
import { mapState } from 'vuex'
import { LCircle } from 'vue2-leaflet'

export default {
    name: 'AccuracyCircle',
    components: {
        LCircle,
    },
    data() {
        return {
            instance: null,
        }
    },
    computed: {
        ...mapState('map', ['mapInstance']),
        ...mapState('tracker', ['activeTrackerOnMap']),
        latLng() {
            const { lat, lng } = this.activeTrackerOnMap.last_gps_measurement
            return [lat, lng]
        },
        radius() {
            return this.activeTrackerOnMap?.last_gps_measurement?.gps_accuracy
        },
    },
}
</script>
