<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { findRealParent } from 'vue2-leaflet'
import { LatLngBounds, featureGroup, icon, marker, polyline } from 'leaflet'
import geometryutil from 'leaflet-geometryutil'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

import { DirectionalMarker } from '../leaflet/DirectionalMarker'
import { NonOverlappingFeatureGroup } from '../leaflet/NonOverlappingFeatureGroup'

momentDurationFormatSetup(moment)

const FLAG_ICON = require('@/assets/icons/flag.svg')
const PIN_ICON = require('@/assets/icons/pin.svg')
const SHOWING_MARKERS_THRESHOLD = 0
const HIGHLIGHTED_TRACE_COLOR = '#ef2655'
const HIGHLIGHTED_MARKER_COLOR = '#f89db2'

export default {
    name: 'TripHistoryLayer',
    props: {
        mapZoom: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            featureGroup: null,
            leafletParentContainer: null,
            markers: [],
            showingMarkers: false,
            tripMarkers: new Map(),
            tripPaths: new Map(),
        }
    },
    computed: {
        ...mapState('tracker', ['assetHistoryLocationId']),
        ...mapState('trip', ['trips']),
        ...mapGetters('map', ['activeLayer']),
        ...mapGetters('trip', ['selectedTrip']),
        traceBorderColor() {
            return this.activeLayer.traceBorderColor || '#228cdb'
        },
        traceColor() {
            return this.activeLayer.traceColor || '#a4c7f5'
        },
        tripCoordinates() {
            return this.trips.map(trip =>
                trip.measurements.reduce(
                    (acc, { id, timestamp, lat, lng }) =>
                        typeof lat === 'number' && typeof lng === 'number'
                            ? [...acc, { id, timestamp, lat, lng }]
                            : acc,
                    []
                )
            )
        },
        markersAllLayer() {
            return new NonOverlappingFeatureGroup(
                [...this.tripMarkers.values()].flat()
            )
        },
        markersSelectedLayer() {
            return (
                this.selectedTrip &&
                new NonOverlappingFeatureGroup(
                    this.tripMarkers.get(this.selectedTrip.id)
                )
            )
        },
        pathsAllLayer() {
            return featureGroup([...this.tripPaths.values()])
        },
        pathsSelectedAllLayer() {
            return (
                this.selectedTrip &&
                this.tripPaths
                    .get(this.selectedTrip.id)
                    .setStyle({ color: HIGHLIGHTED_TRACE_COLOR })
            )
        },
    },
    watch: {
        activeLayer() {
            // When the map style changes, the trip path may need to change style as well
            this.refreshLeafletHistoryLayer()
        },
        mapZoom() {
            this.refreshLeafletHistoryLayer()
        },
        selectedTrip(newTrip, oldTrip) {
            if (oldTrip) {
                this.tripPaths.get(oldTrip.id)?.setStyle({
                    color: this.traceColor,
                })
            }
            this.refreshLeafletHistoryLayer()
            if (newTrip) {
                this.mapFlyToIfNotVisible(
                    this.tripCoordinates[this.trips.indexOf(newTrip)]
                )
            }
        },
        trips() {
            this.$emit('alignMap', this.tripCoordinates.flat())
            Promise.all([
                this.getTripPaths(this.trips, this.tripCoordinates),
                this.getTripMarkers(this.trips, this.tripCoordinates),
            ]).then(([paths, markers]) => {
                this.tripPaths = paths
                this.tripMarkers = markers
                this.refreshLeafletHistoryLayer()
            })
        },
    },
    mounted() {
        this.leafletParentContainer = findRealParent(this.$parent, true)
        this.refreshLeafletHistoryLayer()
        this.$emit('alignMap', this.tripCoordinates.flat())
    },
    beforeDestroy() {
        this.removeTripHistoryLayer()
    },
    methods: {
        ...mapMutations('tracker', ['setAssetHistoryPointProposed']),
        ...mapMutations('trip', ['setSelectedTripId']),
        addTripHistoryLayer() {
            if (this.trips?.length) {
                this.featureGroup = featureGroup().addTo(
                    this.leafletParentContainer.mapObject
                )
                setTimeout(() => {
                    this.featureGroup.addLayer(
                        this.selectedTrip
                            ? this.pathsSelectedAllLayer
                            : this.pathsAllLayer
                    )
                    setTimeout(() => {
                        if (this.mapZoom >= SHOWING_MARKERS_THRESHOLD) {
                            this.featureGroup.addLayer(
                                this.selectedTrip
                                    ? this.markersSelectedLayer
                                    : this.markersAllLayer
                            )
                        }
                    })
                })
            }
        },
        createTripEndMarker(assetHistoryEntry) {
            const size = 30
            return marker(assetHistoryEntry, {
                axItem: assetHistoryEntry,
                icon: icon({
                    iconUrl: FLAG_ICON,
                    iconSize: [size, size],
                    iconAnchor: [5, size],
                    tooltipAnchor: [size / 2, -size / 2],
                }),
            })
        },
        createTripDirectionMarkers(trip, positions = []) {
            let tripDuration = null
            let tripStart = null
            let tripEnd = null

            if (trip.last_measurement) {
                tripDuration = this.formatDuration(
                    new Date(trip.last_measurement.timestamp) -
                        new Date(trip.first_measurement.timestamp)
                )
                tripStart = this.formatTime(trip.first_measurement.timestamp)
                tripEnd = this.formatTime(trip.last_measurement.timestamp)
            }

            return positions.map((position, i) => {
                let marker = null

                if (trip.first_measurement.id === position.id) {
                    marker = this.createTripStartMarker(position)
                } else if (trip.last_measurement.id === position.id) {
                    marker = this.createTripEndMarker(position)
                } else {
                    marker = this.createTripMarker(position, positions[i + 1])
                }

                marker.on('click', () => {
                    this.setAssetHistoryPointProposed([
                        position.lat,
                        position.lng,
                    ])
                })

                let tooltipContent = this.formatTime(position.timestamp)
                if (trip.last_measurement) {
                    tooltipContent +=
                        `<hr>${this.$t('duration')}: ${tripDuration}` +
                        `<br>${this.$t('start')}: ${tripStart}` +
                        `<br>${this.$t('end')}: ${tripEnd}`
                    if (typeof trip.trip_distance === 'number') {
                        tooltipContent += `<br>${this.$t(
                            'distance'
                        )}: ${this.formatDistance(trip.trip_distance)}`
                    }
                }

                return marker.bindTooltip(tooltipContent)
            })
        },
        createTripMarker(position, positionNext) {
            return new DirectionalMarker(position, {
                axItem: position,
                bearing: positionNext
                    ? geometryutil.bearing(position, positionNext)
                    : 0,
                color:
                    this.assetHistoryLocationId === position.id
                        ? HIGHLIGHTED_MARKER_COLOR
                        : this.traceBorderColor,
                fillOpacity: 1,
                opacity: 1,
                scale: 1.6,
                weight: 0,
            })
        },
        createTripStartMarker(assetHistoryEntry) {
            const size = 30
            return marker(assetHistoryEntry, {
                axItem: assetHistoryEntry,
                icon: icon({
                    iconUrl: PIN_ICON,
                    iconSize: [size, size],
                    iconAnchor: [size / 2, size],
                    tooltipAnchor: [size / 2, -size / 2],
                }),
            })
        },
        createTripPathLine(trip, positions) {
            const pathLine = polyline(positions, {
                color: this.traceColor,
                interactive: true,
                weight: 7,
            })
                .on('click', () => {
                    if (this.selectedTrip) {
                        this.setSelectedTripId(null)
                    } else {
                        this.setSelectedTripId(trip.id)
                        this.$nextTick(() => {
                            this.leafletParentContainer.mapObject.once(
                                'click',
                                () => this.setSelectedTripId(null)
                            )
                        })
                    }
                })
                .on('mouseover', () => {
                    if (!this.selectedTrip) {
                        this.tripPaths.get(trip.id).setStyle({
                            color: HIGHLIGHTED_TRACE_COLOR,
                        })
                    }
                })
                .on('mouseout', () => {
                    if (!this.selectedTrip) {
                        this.tripPaths.get(trip.id).setStyle({
                            color: this.traceColor,
                        })
                    }
                })

            if (trip.length > 1) {
                const firstPosition = trip[0]
                const lastPosition = trip[trip.length - 1]
                const tripDuration = this.formatDuration(
                    new Date(lastPosition.timestamp) -
                        new Date(firstPosition.timestamp)
                )
                const tripStart = this.formatTime(firstPosition.timestamp)
                const tripEnd = this.formatTime(lastPosition.timestamp)
                const tooltipContent =
                    `${this.$t('duration')}: ${tripDuration}<br>` +
                    `${this.$t('start')}: ${tripStart}<br>` +
                    `${this.$t('end')}: ${tripEnd}`
                pathLine.bindTooltip(tooltipContent, {
                    sticky: true,
                })
            }

            return pathLine
        },
        formatDistance(distance = 0) {
            return distance >= 1000
                ? `${(distance / 1000).toFixed(2)}km`
                : `${distance}m`
        },
        formatDuration(duration = 0) {
            return moment.duration(duration).format('h[h] mm[m] ss[s]')
        },
        formatTime(timestamp) {
            return moment(timestamp).format('DD.MM.YYYY HH:mm:ss')
        },
        getTripMarkers(trips, coordinates) {
            const markers = new Map()
            let i = -1
            return new Promise(resolve => {
                const create = () => {
                    if (++i < trips.length) {
                        setTimeout(create)
                        markers.set(
                            trips[i].id,
                            this.createTripDirectionMarkers(
                                trips[i],
                                coordinates[i]
                            )
                        )
                    } else {
                        resolve(markers)
                    }
                }
                create()
            })
        },
        getTripPaths(trips, coordinates) {
            const paths = new Map()
            let i = -1
            return new Promise(resolve => {
                const create = () => {
                    if (++i < trips.length) {
                        setTimeout(create)
                        paths.set(
                            trips[i].id,
                            this.createTripPathLine(trips[i], coordinates[i])
                        )
                    } else {
                        resolve(paths)
                    }
                }
                create()
            })
        },
        mapFlyToIfNotVisible(positions) {
            const viewBounds = this.leafletParentContainer.mapObject.getBounds()
            const tripBounds = new LatLngBounds(positions)
            if (!viewBounds.contains(tripBounds)) {
                this.leafletParentContainer.mapObject.flyToBounds(tripBounds)
            }
        },
        refreshLeafletHistoryLayer() {
            this.removeTripHistoryLayer()
            this.addTripHistoryLayer()
        },
        removeTripHistoryLayer() {
            if (this.featureGroup) {
                this.leafletParentContainer.mapObject.removeLayer(
                    this.featureGroup
                )
            }
        },
    },
    render(h) {
        return h() // avoid warning message because we don't have a template
    },
}
</script>

<i18n>
{
    "en": {
        "distance": "Distance",
        "duration": "Duration",
        "end": "End",
        "start": "Start"
    },
    "de": {
        "distance": "Distanz",
        "duration": "Dauer",
        "end": "Ende",
        "start": "Start"
    },
    "fr": {
        "distance": "Distance",
        "duration": "Durée",
        "end": "Fin",
        "start": "Début"
    },
    "it": {
        "distance": "Distanza",
        "duration": "Durata",
        "end": "Fine",
        "start": "Inizio"
    }
}
</i18n>
