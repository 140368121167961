<template>
    <LGeoJson v-if="geojson" :geojson="geojson" :options="geojsonOptions" />
</template>

<script>
import { LGeoJson } from 'vue2-leaflet'

import { httpHelper } from '@/utils'

const colors = {
    bahnhofgrundflaeche: 'crimson',
    lift: 'darkslategray',
    perron: 'mediumblue',
    rolltreppe: 'darkslategray',
    shops: 'darkmagenta',
    treppe: 'darkslategray',
}

export default {
    name: 'LocationGeoJson',
    components: {
        LGeoJson,
    },
    props: {
        geojsonUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            geojson: null,
            geojsonOptions: {
                style(feature) {
                    return {
                        color: colors[feature.properties.type] || 'black',
                        opacity: 0.5,
                        weight: 1,
                    }
                },
                onEachFeature(feature, layer) {
                    if (layer.feature.properties.type) {
                        layer.bindPopup(layer.feature.properties.type)
                    }
                },
            },
        }
    },
    async mounted() {
        const { data } = await httpHelper.get(this.geojsonUrl)
        this.geojson = data.geojson
    },
}
</script>
