<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { circleMarker, featureGroup, polyline, tooltip } from 'leaflet'
import { findRealParent } from 'vue2-leaflet'
import moment from 'moment'

const HIGHLIGHTED_TRACE_COLOR = '#ef2655'

export default {
    name: 'HistoryLayer',
    data() {
        return {
            leafletParentContainer: null,
            featureGroup: null,
        }
    },
    computed: {
        ...mapState('tracker', ['assetHistory', 'assetHistoryLocationId']),
        ...mapGetters('map', ['activeLayer']),
        traceColor() {
            return this.activeLayer.traceColor || '#a4c7f5'
        },
        traceBorderColor() {
            return this.activeLayer.traceBorderColor || '#228cdb'
        },
        assetHistoryFiltered() {
            if (!this.assetHistory) {
                return []
            }

            return this.assetHistory.filter(
                item =>
                    (item.kind === 'gps-fix' ||
                        item.kind === 'indoor' ||
                        item.kind === 'wifi-report') &&
                    item.lat &&
                    item.lng &&
                    item.is_position_accurate
            )
        },
        assetHistoryCoordinates() {
            return this.assetHistoryFiltered.map(item => [item.lat, item.lng])
        },
        selectedAssetHistoryLocation() {
            return this.assetHistoryFiltered.find(
                item => item.id == this.assetHistoryLocationId
            )
        },
        leafletMarkers() {
            return this.assetHistoryFiltered.reduce((markers, position) => {
                markers.set(position.id, this.createMarker(position))
                return markers
            }, new Map())
        },
        leafletLayers() {
            return {
                markers: featureGroup([...this.leafletMarkers.values()]),
                paths: polyline(this.assetHistoryCoordinates, {
                    color: this.traceColor,
                    weight: 3,
                }),
            }
        },
    },
    watch: {
        assetHistoryFiltered() {
            this.refreshLeafletHistoryLayer()
            if (this.assetHistoryFiltered) {
                this.$emit('alignMap', this.assetHistoryCoordinates)
            }
        },
        assetHistoryLocationId(newId, oldId) {
            const selectedMarker = this.leafletMarkers.get(newId)
            selectedMarker.setStyle({ color: HIGHLIGHTED_TRACE_COLOR })
            if (oldId) {
                this.leafletMarkers
                    .get(oldId)
                    .setStyle({ color: this.traceBorderColor })
            }
            selectedMarker.bringToFront()
        },
        selectedAssetHistoryLocation(location) {
            if (location) {
                this.$emit('alignMap', [[location.lat, location.lng]])
            }
        },
    },
    mounted() {
        this.leafletParentContainer = findRealParent(this.$parent, true)
    },
    methods: {
        ...mapMutations('tracker', ['setAssetHistoryLocationId']),
        addLeafletHistoryLayer() {
            if (!this.assetHistoryFiltered?.length) {
                return
            }

            this.featureGroup = featureGroup()
            this.featureGroup.addLayer(this.leafletLayers.paths)
            this.featureGroup.addLayer(this.leafletLayers.markers)
            this.leafletParentContainer.mapObject.addLayer(this.featureGroup)
            this.leafletLayers.markers.bringToFront()
        },
        createMarker(position) {
            const marker = circleMarker(position, {
                axItem: position,
                color: this.traceBorderColor,
                fillOpacity: 1,
                opacity: 1,
                radius: 4,
                weight: 0,
            })

            const markerTooltip = tooltip()
            markerTooltip.setContent(this.getTimeFormatted(position.timestamp))
            marker.bindTooltip(markerTooltip)

            marker.on('click', () => {
                this.setAssetHistoryLocationId(position.id)
            })

            return marker
        },
        getTimeFormatted(timestamp) {
            return moment(timestamp).format('DD.MM.YYYY HH:mm:ss')
        },
        refreshLeafletHistoryLayer() {
            this.removeLeafletLayer()
            this.addLeafletHistoryLayer()
        },
        removeLeafletLayer() {
            if (this.featureGroup) {
                this.leafletParentContainer.mapObject.removeLayer(
                    this.featureGroup
                )
            }
        },
    },
    render(h) {
        return h() // avoid warning message because we don't have a template
    },
}
</script>
