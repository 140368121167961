export default {
    methods: {
        getLatLngsFromLocationGeofence: function(geofence) {
            let coordinates = []

            geofence.coordinates[0].forEach(function(l) {
                coordinates.push([l[1], l[0]])
            })

            return coordinates
        },
    },
}
